import { Octokit } from "@octokit/rest";
import { Endpoints } from "@octokit/types";
import get from "lodash/get";
import omit from "lodash/omit";
import { useMutation, useQueries, useQuery } from "react-query";

const octokit = new Octokit({
  auth: process.env.REACT_APP_GH_API_PAT,
});

type WithOptionalOwner<T> = Omit<T, "owner"> & {
  owner?: string;
};

export const useDeploymentList = (
  deploymentParams: WithOptionalOwner<
    Endpoints["GET /repos/{owner}/{repo}/deployments"]["parameters"]
  >[]
) => {
  return useQueries(
    deploymentParams.map((params) => ({
      queryKey: ["deployments_list", params],
      queryFn: () =>
        octokit.rest.repos.listDeployments({
          owner: params.owner || "leanspace",
          per_page: 1,
          ...omit(params, "owner"),
        }),
    }))
  );
};

export const useDeploymentStatuses = (
  deploymentParams: WithOptionalOwner<
    Endpoints["GET /repos/{owner}/{repo}/deployments"]["parameters"]
  >[]
) => {
  const deploymentsLists = useDeploymentList(deploymentParams);

  const deploymentIds = deploymentsLists.map((deploymentsResult) =>
    get(deploymentsResult, "data.data.[0].id", null)
  );

  return useQueries(
    deploymentIds.map((deployment_id, index) => ({
      queryKey: ["deployment_status", deployment_id],
      queryFn: () =>
        octokit.rest.repos.listDeploymentStatuses({
          deployment_id: Number(deployment_id),
          owner: deploymentParams[index].owner || "leanspace",
          repo: deploymentParams[index].repo,
          per_page: 1,
        }),
      enabled: Boolean(deployment_id),
    }))
  );
};

export const useReleases = (
  params: WithOptionalOwner<
    Endpoints["GET /repos/{owner}/{repo}/releases"]["parameters"]
  >
) => {
  return useQuery(["releases", params], () =>
    octokit.rest.repos.listReleases({
      owner: params.owner || "leanspace",
      ...omit(params, "owner"),
    })
  );
};

export const useTriggerWorkflow = () => {
  return useMutation(
    (
      params: WithOptionalOwner<
        Endpoints["POST /repos/{owner}/{repo}/actions/workflows/{workflow_id}/dispatches"]["parameters"]
      >
    ) =>
      octokit.rest.actions.createWorkflowDispatch({
        owner: params.owner || "leanspace",
        ...omit(params, "owner"),
      })
  );
};

export const useFileContent = (repo: string, path: string) => {
  return useQuery(["manifest", repo, path], async () => {
    const response = await octokit.rest.repos.getContent({
      owner: "leanspace",
      repo,
      path,
    });

    const content = get(response, "data.content", null);
    return content ? atob(content) : null;
  });
};
