import { Typography } from "@mui/material";
import { useMember } from "Hooks/members";
import React from "react";
const MemberName: React.FC<React.PropsWithChildren<{ memberId: string }>> = ({
  memberId,
}) => {
  const queryResult = useMember(memberId);
  return !queryResult.data ? (
    <Typography>{memberId}</Typography>
  ) : (
    <Typography>{queryResult.data?.name}</Typography>
  );
};
export default MemberName;
