import {
  DeliveryRequest,
  DeliveryResponse,
} from "@leanspace/js-client/dist/types/UsageMetrics";
import {
  Chip,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { Dictionary, uniq } from "lodash";
import React from "react";
import { Check, X } from "react-feather";

import MemberName from "./MemberName";
const TableView: React.FC<
  React.PropsWithChildren<{
    manualRequests: DeliveryRequest[];
    deliveriesByRequestId: Dictionary<DeliveryResponse[]>;
    providers: string[];
  }>
> = ({ manualRequests, deliveriesByRequestId, providers }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th">Date</TableCell>
            <TableCell component="th">Requested on</TableCell>
            <TableCell component="th">By user</TableCell>
            <TableCell component="th">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {manualRequests.map((request) => {
            const deliveries = deliveriesByRequestId[request.id] || [];
            const providersForDelivery = uniq(
              deliveries.map((delivery) => delivery.provider)
            );

            return (
              <TableRow key={request.id}>
                <TableCell>
                  {dayjs(request.measuredFromIncluded).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell>
                  {dayjs(request.createdAt).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell>
                  {request.createdBy === "anonymousUser" ? (
                    request.createdBy
                  ) : (
                    <MemberName memberId={request.createdBy}></MemberName>
                  )}
                </TableCell>
                <TableCell>
                  <Paper
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      listStyle: "none",
                      p: 0.5,
                      m: 0,
                    }}
                    component="ul"
                  >
                    {providers.map((provider) => (
                      <ListItem
                        key={provider}
                        sx={{ width: "auto", padding: 0.5 }}
                      >
                        <Chip
                          label={provider}
                          variant="outlined"
                          color={
                            providersForDelivery.includes(provider)
                              ? "success"
                              : "default"
                          }
                          icon={
                            providersForDelivery.includes(provider) ? (
                              <Check size={16} />
                            ) : (
                              <X size={16} />
                            )
                          }
                          size="small"
                        />
                      </ListItem>
                    ))}
                  </Paper>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableView;
