import { Box, Typography } from "@mui/material";
import React from "react";

import DemoDeployment from "./DemoDeployment";
import ProdDeployment from "./ProdDeployment";

const PlatformOverview: React.FC = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" sx={{ paddingLeft: 2, marginBottom: 3 }}>
        Deploy to Demo
      </Typography>
      <DemoDeployment />

      <Typography variant="h5" sx={{ paddingLeft: 2, marginBottom: 3 }}>
        Promote to Prod
      </Typography>
      <ProdDeployment />
    </Box>
  );
};

export default PlatformOverview;
