import React from "react";
import { ENV_NAME } from "Types/env";

const UserContext = React.createContext<{
  isLoggedIn: boolean;
  token?: string;
  login: (token: string) => void;
  env: ENV_NAME;
}>({
  isLoggedIn: false,
  token: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: () => {},
  env: "PROD",
});

export default UserContext;
