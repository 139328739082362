import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTenants } from "Hooks/tenants";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import CreateTenant from "./Tenant/CreateTenant";

const Main: React.FC = ({}) => {
  const [isCreatingTenant, setIsCreatingTenant] = useState(false);

  const { data } = useTenants();
  const tenants = data?.content || [];

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2}>
        {tenants.map((tenant: any) => (
          <Grid item xs={3} key={tenant.id}>
            <Card>
              <CardMedia
                component="div"
                sx={{
                  backgroundColor: "primary.light",
                  color: "black",
                  height: 70,
                  paddingTop: 2,
                  paddingLeft: 2,
                }}
              >
                <Typography variant="h5" component="div">
                  {tenant.name}
                </Typography>
              </CardMedia>
              <CardContent sx={{ height: 90 }}>
                <Typography variant="body2">{tenant.description}</Typography>
              </CardContent>
              <CardActions sx={{ padding: 2 }}>
                <Link
                  component={RouterLink}
                  to={`/tenants/${tenant.id}`}
                  underline="hover"
                >
                  Explore
                </Link>
              </CardActions>
            </Card>
          </Grid>
        ))}
        <Grid item xs={3}>
          <Card>
            <CardMedia
              component="div"
              sx={{
                backgroundColor: "primary.dark",
                color: "black",
                height: 70,
                paddingTop: 2,
                paddingLeft: 2,
              }}
            >
              <Typography variant="h5" component="div">
                New Tenant
              </Typography>
            </CardMedia>
            <CardContent sx={{ height: 90 }} />
            <CardActions sx={{ padding: 2 }}>
              <Button onClick={() => setIsCreatingTenant(true)}>Create</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <CreateTenant
        open={isCreatingTenant}
        onClose={() => setIsCreatingTenant(false)}
        existingTenants={tenants}
      />
    </Box>
  );
};

export default Main;
