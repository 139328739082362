import { Auth } from "@aws-amplify/auth";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React, { ReactNode, useMemo } from "react";
import { Award, ExternalLink, LogOut, Radio, Users } from "react-feather";
import {
  Link as RouterLink,
  Outlet,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import { ReactComponent as LeanspaceLogo } from "../Assets/leanspace_logo.svg";

const ListItemLink: React.FC<
  React.PropsWithChildren<{
    icon: ReactNode;
    primary: string;
    to: string;
    disabled?: boolean;
  }>
> = ({ icon, primary, to, disabled }) => {
  const resolved = useResolvedPath(to);
  const match = Boolean(useMatch({ path: resolved.pathname, end: true }));

  const renderLink = useMemo(
    () =>
      function Link(props: any) {
        const { children } = props;
        return to.startsWith("http") ? (
          <a href={to} target="_blank" rel="noreferrer" {...props}>
            {children}
            <ExternalLink size={14} />
          </a>
        ) : (
          <RouterLink to={to} {...props} />
        );
      },
    [to]
  );

  return (
    <ListItemButton component={renderLink} disabled={disabled} selected={match}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItemButton>
  );
};

const Main: React.FC = () => {
  const navigate = useNavigate();
  const logout = async () => {
    await Auth.signOut({ global: true });
    navigate("/");
    window.location.reload();
  };

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <Drawer
        variant="permanent"
        open
        sx={{
          width: 300,
        }}
        PaperProps={{
          sx: { width: 300 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 1,
          }}
        >
          <LeanspaceLogo height={130} />
          <Typography sx={{ fontSize: 16 }} variant="overline">
            Admin Tool ({localStorage.getItem("env")})
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <List
          component="nav"
          sx={{
            width: "100%",
          }}
        >
          <ListItemLink to="/tenants" primary="Tenants" icon={<Users />} />
          <ListItemLink
            to="/collection"
            primary="Usage Metrics"
            icon={<Radio />}
          />
          <Divider sx={{ my: 1 }} />
          <ListItemLink
            to="/platform"
            primary="Deploy Platform"
            icon={<Award />}
          />
          <Divider sx={{ my: 1 }} />
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <LogOut />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItemButton>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Main;
