const AWS_REGION = "eu-central-1";

export const COGNITO_POOLS = {
  DEV: {
    clientAppId: "2aof00bhnk8bl5m919brurfj5q",
    userPoolWebClientId: "2aof00bhnk8bl5m919brurfj5q",
    accountId: "leanspace",
    identityPoolId: "eu-central-1:76d0aac4-f947-48e1-bc4a-ba82f53d1734",
    userPoolId: "eu-central-1_gMS9jwzCN",
    region: AWS_REGION,
  },
  DEMO: {
    clientAppId: "337beoumrcql1n1bihvfed5a1i",
    userPoolWebClientId: "337beoumrcql1n1bihvfed5a1i",
    accountId: "leanspace",
    identityPoolId: "eu-central-1:b4c1ce65-acf4-4d5b-bcca-7c220c98fd34",
    userPoolId: "eu-central-1_KtYM219ce",
    region: AWS_REGION,
  },
  PROD: {
    clientAppId: "6041nug322chogs2li9b3nuq1f",
    userPoolWebClientId: "6041nug322chogs2li9b3nuq1f",
    accountId: "leanspace",
    identityPoolId: "eu-central-1:ce502920-6f3b-47e0-a063-5279ce4a23af",
    userPoolId: "eu-central-1_0U6kJK2K0",
    region: AWS_REGION,
  },
};
