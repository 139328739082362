import { useLeanspaceAPI } from "@leanspace/js-client/dist/react";
import { AccumulatedUsageMetric } from "@leanspace/js-client/dist/types/UsageMetrics";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useTenants } from "Hooks/tenants";
import keyBy from "lodash/keyBy";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

const Export: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const yesterday = dayjs().subtract(1, "day");
  const now = dayjs();

  const [startMonth, setStartMonth] = useState<Dayjs | null>(
    dayjs().startOf("month")
  );
  const [endMonth, setEndMonth] = useState<Dayjs | null>(yesterday);
  const [selectedTenants, setSelectedTenants] = useState<string[]>([]);
  const [exportProgress, setExportProgress] = useState(0);

  const { data: tenantsData, isLoading: isLoadingTenants } = useTenants();
  const tenants = tenantsData?.content ?? [];
  const tenantsById = keyBy(tenants, "id");

  useEffect(
    function preselectAllTenants() {
      setSelectedTenants(tenants.map((tenant) => tenant.id));
    },
    [tenants]
  );

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setSelectedTenants(typeof value === "string" ? value.split(",") : value);
  };

  const { usageMetrics } = useLeanspaceAPI();

  const exportToCSV = async (from: Dayjs, to: Dayjs, tenantIds: string[]) => {
    try {
      setExportProgress(1);
      const shortDateFormat = "YYYY-MM-DD";

      const startTime = from.startOf("month");
      const endTime =
        to.month() === yesterday.month() ? yesterday : to.endOf("month");

      // init clients for all the tenants
      const includedTenants = tenantIds
        .map((id) => tenantsById[id])
        .sort((a, z) => a.name.localeCompare(z.name));

      setExportProgress(10);

      // load usage metrics for all tenants
      const progressPercentageForAllTenants = 80;
      const progressIncrement =
        progressPercentageForAllTenants / includedTenants.length;
      const results = await Promise.allSettled(
        includedTenants.map(async ({ name }) => {
          const accumulatedMetrics = await usageMetrics.accumulatedByTenant({
            tenant: name,
            measuredFrom: startTime.format(shortDateFormat),
            measuredTo: endTime.format(shortDateFormat),
          });
          setExportProgress((progress) => progress + progressIncrement);
          return accumulatedMetrics;
        })
      );

      setExportProgress(90);
      const allUsageMetrics = results
        .filter((result) => result.status == "fulfilled")
        .map(
          (result) =>
            (result as PromiseFulfilledResult<AccumulatedUsageMetric[]>).value
        );
      const failedTenants = results.filter(
        (result) => result.status == "rejected"
      );
      failedTenants.length &&
        enqueueSnackbar(
          `Export failed for tenant(s): ${failedTenants.map(
            (result) =>
              (result as PromiseRejectedResult).reason.config.url.match(
                "(?<=//)(.*?)(?=-)"
              )[0]
          )}`,
          { variant: "error" }
        );
      // generate the actual csv
      const csv = [
        `tenant,service,metric,description,value,unit,complete data`,
        ...allUsageMetrics.flatMap((usageMetrics, index) =>
          usageMetrics.map(
            (metric) =>
              `${includedTenants[index].name},${metric.provider},${
                metric.type
              },${metric.description},${Math.floor(metric.accumulatedValue)},${
                metric.unit
              },${metric.complete ? "yes" : "no"}`
          )
        ),
      ];

      setExportProgress(95);

      // trigger file download
      const fileTitle = `Leanspace combined usage report (${
        includedTenants.length === 1
          ? includedTenants[0].name
          : `${includedTenants.length} tenants`
      }) - From ${startTime.format(shortDateFormat)} to ${endTime.format(
        shortDateFormat
      )} as of ${dayjs().format("YYYY-MM-DD HH:mm")}.csv`;
      const csvContent = csv.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      setExportProgress(99);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileTitle;
      a.click();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Export failed :(", { variant: "error" });
    } finally {
      setExportProgress(0);
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <Typography variant="overline" gutterBottom>
        Usage Metrics Export
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <DatePicker
          label="From"
          views={["month", "year"]}
          value={startMonth}
          onChange={(value) => setStartMonth(value)}
          inputFormat="YYYY-MM"
          mask="____-__"
          renderInput={(params) => <TextField {...params} />}
          maxDate={endMonth}
        />
        <DatePicker
          label="To"
          views={["month", "year"]}
          value={endMonth}
          onChange={(value) => setEndMonth(value)}
          inputFormat="YYYY-MM"
          mask="____-__"
          renderInput={(params) => <TextField {...params} />}
          minDate={startMonth}
          maxDate={now}
        />
        <FormControl sx={{ minWidth: "250px" }}>
          <InputLabel id="tenants-label">Tenants</InputLabel>
          <Select
            multiple
            name="tenants"
            label="Tenants"
            labelId="tenants-label"
            value={selectedTenants}
            renderValue={(selected) =>
              selected.length === tenants.length
                ? "All tenants"
                : selected
                    .map((tenantId) => tenantsById[tenantId].name)
                    .join(", ")
            }
            onChange={handleChange}
          >
            {tenants.map((tenant) => (
              <MenuItem key={tenant.id} value={tenant.id}>
                <Checkbox checked={selectedTenants.includes(tenant.id)} />
                <ListItemText primary={tenant.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          onClick={() =>
            exportToCSV(startMonth as Dayjs, endMonth as Dayjs, selectedTenants)
          }
          disabled={
            !startMonth ||
            !endMonth ||
            startMonth > endMonth ||
            selectedTenants.length === 0 ||
            isLoadingTenants ||
            exportProgress !== 0
          }
        >
          Export to CSV
        </Button>
      </Box>
      {exportProgress !== 0 && (
        <LinearProgress variant="determinate" value={exportProgress} />
      )}
    </Box>
  );
};

export default Export;
