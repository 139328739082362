import {
  Box,
  Divider,
  IconButton,
  Skeleton,
  Toolbar,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTenant } from "Hooks/tenants";
import React from "react";
import { ChevronsLeft } from "react-feather";
import { Link, useParams } from "react-router-dom";

import DeleteTenant from "./DeleteTenant";
import Members from "./Members";
import UsageMetrics from "./UsageMetrics";

const Tenant: React.FC = ({}) => {
  const params = useParams();

  const { data: tenant, isLoading } = useTenant(params.tenantId as string);

  return (
    <>
      <Toolbar>
        <IconButton
          LinkComponent={(props) => <Link {...props} to="/tenants" />}
          href="/tenants"
        >
          <ChevronsLeft />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          {tenant ? tenant.name : <Skeleton width={200} />}
        </Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography sx={{ padding: 2 }} variant="h5">
              Members
            </Typography>
            {tenant && <Members tenant={tenant.name} />}
            <Divider sx={{ margin: 2 }} />
            <Typography sx={{ padding: 2 }} variant="h5">
              Usage Metrics (current month)
            </Typography>
            {tenant && <UsageMetrics tenant={tenant.name} />}
            <Divider sx={{ margin: 2 }} />
            <Typography sx={{ padding: 2 }} variant="h5">
              Delete tenant
            </Typography>
            {tenant && <DeleteTenant tenant={tenant} />}
          </>
        )}
      </Box>
    </>
  );
};

export default Tenant;
