import { Tenant } from "@leanspace/js-client/dist/types/Tenants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import UserContext from "Contexts/UserContext";
import { useTriggerWorkflow } from "Hooks/Github";
import { useSnackbar } from "notistack";
import React, { SyntheticEvent, useContext, useState } from "react";

import BuildInProgressCheck from "./BuildInProgressCheck";

interface CreateTenantProps {
  open: boolean;
  onClose: () => void;
  existingTenants: Tenant[];
}

const CreateTenant: React.FC<React.PropsWithChildren<CreateTenantProps>> = ({
  open,
  onClose,
  existingTenants,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { env, token } = useContext(UserContext);
  const [tenantNameError, setTenantNameError] = useState<string | null>(null);
  const { mutateAsync: triggerWorkflow } = useTriggerWorkflow();
  const [hasTriggeredTenantCreation, setHasTriggeredTenantCreation] =
    useState(false);

  const validateTenantName = (event: SyntheticEvent) => {
    const tenantName = (event.target as HTMLInputElement).value;
    const isTenantNameDulicated = existingTenants.some(
      (tenant) => tenant.name === tenantName
    );

    const isInvalidName = /^[a-z0-9]{4,30}$/.test(tenantName) === false;
    if (isTenantNameDulicated) {
      setTenantNameError("Already in use");
      return;
    }
    if (isInvalidName) {
      setTenantNameError(
        "Must be between 4 and 30 lowercase letters and numbers"
      );
      return;
    }

    setTenantNameError(null);
  };

  const createTenant = async (event: SyntheticEvent) => {
    try {
      setHasTriggeredTenantCreation(false);
      event.preventDefault();

      const tenantName = (event.target as HTMLFormElement).tenant.value;
      const email = (event.target as HTMLFormElement).email.value;

      await triggerWorkflow({
        workflow_id: "createTenant.yml",
        repo: "leanspace-infra",
        ref: "main",
        inputs: {
          aws_env_name: env === "DEV" ? "develop" : env.toLowerCase(),
          tenant_name: tenantName,
          tenant_email: email.toLowerCase(),
          "leanspace-username": "ignored-username",
          "leanspace-password": "ignored-username",
          "leanspace-token": token ?? "",
        },
      });
      onClose();
      enqueueSnackbar("The creation has started.", { variant: "success" });
      setHasTriggeredTenantCreation(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to create tenant.", {
        variant: "error",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create new tenant on {env}</DialogTitle>
      {hasTriggeredTenantCreation ? (
        <>
          <DialogContent>
            <DialogContentText>
              The tenant creation is in progress.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
            <Button
              href={`https://github.com/leanspace/leanspace-infra/actions/workflows/createTenant.yml?query=is%3Ain_progress`}
              color="success"
            >
              Follow progress
            </Button>
          </DialogActions>
        </>
      ) : (
        <form onSubmit={createTenant}>
          <DialogContent>
            <BuildInProgressCheck />
            <TextField
              autoFocus
              margin="dense"
              id="tenant"
              label="Tenant Name"
              type="text"
              fullWidth
              variant="standard"
              required
              onChange={validateTenantName}
              error={Boolean(tenantNameError)}
              helperText={
                tenantNameError ||
                "Between 4 and 30 lowercase letters and numbers"
              }
            />
            <TextField
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" disabled={Boolean(tenantNameError)}>
              Create
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default CreateTenant;
