import { Button, Grid, Typography } from "@mui/material";
import {
  useDeploymentStatuses,
  useFileContent,
  useTriggerWorkflow,
} from "Hooks/Github";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import React from "react";

const ProdDeployment: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: demoManifestText } = useFileContent(
    "releases",
    "/manifests/demo.json"
  );
  const demoManifest: Record<string, string> = JSON.parse(
    demoManifestText ?? "{}"
  );

  const { data: prodManifestText } = useFileContent(
    "releases",
    "/manifests/prod.json"
  );
  const prodManifest: Record<string, string> = JSON.parse(
    prodManifestText ?? "{}"
  );

  const changesForProd = Object.keys(demoManifest).filter((repoName) => {
    if (prodManifest[repoName] !== demoManifest[repoName]) {
      return true;
    }
    return false;
  });

  const [deployToProdStatus] = useDeploymentStatuses([
    {
      repo: "releases",
      environment: "production",
    },
  ]);

  const isDeploymentToProdRunning = [
    "in_progress",
    "queued",
    "pending",
  ].includes(get(deployToProdStatus, "data.data.[0].state"));

  const { mutateAsync: triggerWorkflow, isLoading: isTriggeringWorkflow } =
    useTriggerWorkflow();

  const startDeploymentToProd = async () => {
    try {
      await triggerWorkflow({
        workflow_id: "deploy-to-prod.yml",
        repo: "releases",
        ref: "main",
      });
      enqueueSnackbar(
        "Started the automated tests, the deployment will begin in a few minutes.",
        { variant: "success" }
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to trigger the deployment.", {
        variant: "error",
      });
    }
  };

  if (changesForProd.length === 0) {
    return (
      <Typography variant="body1" sx={{ paddingLeft: 2, marginBottom: 3 }}>
        No changes to promote.
      </Typography>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ paddingLeft: 2, marginBottom: 3, maxWidth: "800px" }}
    >
      {changesForProd.map((repoName) => (
        <>
          <Grid item xs={5}>
            {repoName}
          </Grid>
          <Grid item xs={3}>
            {prodManifest[repoName]}
          </Grid>
          <Grid item xs={1}>
            👉
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "right" }}>
            {demoManifest[repoName]}
          </Grid>
        </>
      ))}
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <Button
          onClick={startDeploymentToProd}
          variant="contained"
          disabled={isDeploymentToProdRunning || isTriggeringWorkflow}
        >
          PROMOTE
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProdDeployment;
