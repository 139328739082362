import { Autocomplete, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useReleases } from "Hooks/Github";
import { get } from "lodash";
import React, { PropsWithChildren, useEffect, useState } from "react";

interface VersionInputProps {
  repoName: string;
  latestVersion?: string;
  defaultVersion: "dev" | "prod";
}

const VersionInput: React.FC<PropsWithChildren<VersionInputProps>> = ({
  repoName,
  latestVersion,
  defaultVersion,
}) => {
  const latestDevOption = {
    value: "dev",
    label: `Latest on dev (${latestVersion})`,
  };
  const prodOption = {
    value: "prod",
    label: "Don't update",
  };

  const [selectedOption, setSelectedOption] = useState<
    typeof options[number] | null
  >(defaultVersion === "dev" ? latestDevOption : prodOption);

  const { data } = useReleases({
    repo: repoName,
  });

  const releases = get(data, "data", []);

  const options = releases.map((release) => ({
    value: release.tag_name,
    label: `${release.tag_name} (${dayjs(release.published_at).fromNow()})`,
  }));

  options.unshift(latestDevOption);
  options.unshift(prodOption);

  useEffect(() => {
    if (defaultVersion !== selectedOption?.value) {
      setSelectedOption(
        defaultVersion === "dev" ? latestDevOption : prodOption
      );
    }
  }, [defaultVersion]);

  return (
    <>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        value={selectedOption}
        onChange={(_, newValue) => {
          setSelectedOption(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} name={repoName} label="version" />
        )}
      />
      <input type="hidden" name={repoName} value={selectedOption?.value} />
    </>
  );
};

export default VersionInput;
