import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Main from "./Main";
import PlatformOverview from "./PlatformOverview";
import Tenant from "./Tenant";
import TenantList from "./TenantList";
import UsageMetricsCollection from "./UsageMetricsCollection";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="tenants" element={<TenantList />} />
          <Route path="tenants/:tenantId" element={<Tenant />} />
          <Route path="collection" element={<UsageMetricsCollection />} />
          <Route path="platform" element={<PlatformOverview />} />
          <Route index element={<Navigate to="tenants" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
