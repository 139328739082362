import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import {
  useDeploymentStatuses,
  useFileContent,
  useTriggerWorkflow,
} from "Hooks/Github";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";

import VersionInput from "./VersionInput";

const DemoDeployment: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null);
  const [defaultVersion, setDefaultVersion] = useState<"dev" | "prod">("prod");

  const { data: devManifestText } = useFileContent(
    "releases",
    "/manifests/dev.json"
  );
  const devManifest: Record<string, string> = JSON.parse(
    devManifestText ?? "{}"
  );

  const [deployToDemoStatus] = useDeploymentStatuses([
    {
      repo: "releases",
      environment: "demo",
    },
  ]);

  const isDeploymentToDemoRunning = [
    "in_progress",
    "queued",
    "pending",
  ].includes(get(deployToDemoStatus, "data.data.[0].state"));

  const { mutateAsync: triggerWorkflow, isLoading: isTriggeringWorkflow } =
    useTriggerWorkflow();

  const startDeploymentToDemo = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    try {
      event.preventDefault();
      const formValues = new FormData(event.currentTarget);
      const versions: Record<string, string> = {};
      for (const [repoName, repoVersion] of formValues.entries()) {
        versions[repoName] = repoVersion as string;
      }

      await triggerWorkflow({
        workflow_id: "deploy-to-demo.yml",
        repo: "releases",
        ref: "main",
        inputs: {
          IS_CUSTOM_RELEASE: "true",
          CUSTOM_VERSIONS: JSON.stringify(versions),
        },
      });
      enqueueSnackbar(
        "Started the automated tests, the deployment will begin in a few minutes.",
        { variant: "success" }
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to trigger the deployment.", {
        variant: "error",
      });
    }
  };

  const copyManifestToClipboard = () => {
    if (!formRef.current) {
      enqueueSnackbar("Unable to copy the manifest to the clipboard.", {
        variant: "error",
      });
      return;
    }

    const formValues = new FormData(formRef.current);
    const versions: Record<string, string> = {};
    for (const [repoName, repoVersion] of formValues.entries()) {
      versions[repoName] = repoVersion as string;
    }
    navigator.clipboard.writeText(JSON.stringify(versions, null, 2));
    enqueueSnackbar("Copied the manifest to the clipboard.", {
      variant: "success",
    });
  };

  return (
    <form onSubmit={startDeploymentToDemo} ref={formRef}>
      <Grid container spacing={2} sx={{ paddingLeft: 2, maxWidth: "800px" }}>
        {Object.entries(devManifest).map(([repoName, repoVersion]) => (
          <React.Fragment key={repoName}>
            <Grid item xs={7}>
              {repoName}
            </Grid>
            <Grid item xs={5}>
              {repoName === "integration-testing" ? (
                <Autocomplete
                  freeSolo
                  options={[repoVersion]}
                  defaultValue={repoVersion}
                  renderInput={(params) => (
                    <TextField {...params} name={repoName} label="version" />
                  )}
                />
              ) : (
                <VersionInput
                  repoName={repoName}
                  latestVersion={repoVersion}
                  defaultVersion={defaultVersion}
                />
              )}
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <Button
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={() =>
              setDefaultVersion(defaultVersion === "dev" ? "prod" : "dev")
            }
          >
            {defaultVersion === "dev"
              ? "Update nothing"
              : "Update everything to latest"}
          </Button>
          <Button
            variant="outlined"
            onClick={copyManifestToClipboard}
            sx={{ mr: 2 }}
          >
            COPY MANIFEST
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={isDeploymentToDemoRunning || isTriggeringWorkflow}
          >
            DEPLOY
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DemoDeployment;
