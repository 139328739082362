import {
  Button,
  Divider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import {
  useDeliveryRequests,
  useDeliveryResponses,
  useRequestDeliveryMutation,
} from "Hooks/usageMetrics";
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Calendar } from "react-feather";
import { Grid } from "react-feather";

import CalendarView from "./CalendarView";
import Export from "./Export";
import TableView from "./TableView";

const UsageMetricsCollection: React.FC = ({}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedView, setSelectedView] = useState("calendar");
  const [requestedDate, setRequestedDate] = useState<Dayjs | null>(null);
  const { mutateAsync: requestCollection } = useRequestDeliveryMutation();

  const { data } = useDeliveryRequests({
    sort: "createdAt,DESC",
    size: 100,
  });
  const requests = data?.content || [];
  const manualRequests = requests.filter(
    (request) => request.createdBy !== "anonymousUser"
  );

  const onRequestCollection = async () => {
    if (requestedDate === null) return;
    try {
      await requestCollection({
        type: "deliverAll",
        day: requestedDate.format("YYYY-MM-DD"),
      });
    } catch (error) {
      enqueueSnackbar("Failed to request collection", { variant: "error" });
      console.log(error);
    }
  };

  const manualRequestIds = manualRequests.map((request) => request.id);
  const { data: deliveriesData } = useDeliveryResponses({
    requestIds: manualRequestIds,
    size: 100,
    sort: "createdAt,DESC",
  });
  const deliveries = deliveriesData?.content || [];
  const deliveriesByRequestId = groupBy(deliveries, "requestId");

  const providers = uniq(deliveries.map((delivery) => delivery.provider));

  return (
    <Box>
      <Export />
      <Divider />
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="overline" gutterBottom>
          Usage metric collection
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box display={"flex"} justifyContent={"center"}>
            <DatePicker
              label="Collection date"
              value={requestedDate}
              onChange={(value) => setRequestedDate(value)}
              renderInput={(params) => <TextField {...params} />}
            />
            <Button
              onClick={() => onRequestCollection()}
              disabled={requestedDate === null}
            >
              Collect
            </Button>
          </Box>
          <Box>
            <ToggleButtonGroup
              exclusive
              value={selectedView}
              onChange={(event, value) => setSelectedView(value)}
            >
              <ToggleButton value={"calendar"}>
                <Typography>
                  <Calendar />
                </Typography>
              </ToggleButton>
              <ToggleButton value={"table"}>
                <Typography>
                  <Grid />
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Box>
      {selectedView === "calendar" ? (
        <CalendarView
          requests={requests}
          deliveriesByRequestId={deliveriesByRequestId}
          providers={providers}
        />
      ) : (
        <TableView
          manualRequests={manualRequests}
          deliveriesByRequestId={deliveriesByRequestId}
          providers={providers}
        />
      )}
    </Box>
  );
};

export default UsageMetricsCollection;
