import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useUsageMetrics } from "Hooks/usageMetrics";
import capitalize from "lodash/capitalize";
import React from "react";

interface UsageMetricsProps {
  tenant: string;
}
const UsageMetrics: React.FC<React.PropsWithChildren<UsageMetricsProps>> = ({
  tenant,
}) => {
  const { data } = useUsageMetrics(tenant);
  const usageMetrics = data || [];

  return (
    <Grid container spacing={2}>
      {usageMetrics
        .filter((usageMetric) => usageMetric.accumulatedValue > 0)
        .map((usageMetric) => (
          <Grid item xs={4} key={usageMetric.type}>
            <Card>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {usageMetric.provider} —{" "}
                  {capitalize(usageMetric.type.split("_").join(" "))}
                </Typography>
                <Typography component="p" variant="h4">
                  {usageMetric.accumulatedValue ===
                  Math.round(usageMetric.accumulatedValue)
                    ? usageMetric.accumulatedValue
                    : usageMetric.accumulatedValue.toFixed(3)}
                </Typography>

                {usageMetric.unit !== "Count" && (
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {usageMetric.unit}
                  </Typography>
                )}
                <Typography variant="body2">
                  {usageMetric.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default UsageMetrics;
