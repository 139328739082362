import {
  DeliveryRequest,
  DeliveryResponse,
} from "@leanspace/js-client/dist/types/UsageMetrics";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import uniq from "lodash/uniq";
import React, { useEffect, useState } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";

import TableView from "./TableView";
const Item = styled(Stack)(({}) => ({
  ...{
    height: "10vh",
    padding: "1vh",
    cursor: "pointer",
    border: "0.1px solid #eee6",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#eee5",
    },
  },
}));
const ItemHeader = styled(Paper)(({}) => ({
  ...{
    height: "5vh",
    padding: "1vh",
    cursor: "pointer",
    border: "0.1px solid #eee6",
    backgroundColor: "#eee5",
  },
}));
const CalendarView: React.FC<
  React.PropsWithChildren<{
    requests: DeliveryRequest[];
    deliveriesByRequestId: Record<string, DeliveryResponse[]>;
    providers: string[];
  }>
> = ({ requests, deliveriesByRequestId, providers }) => {
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [open, setOpen] = React.useState(false);
  const [selectedRequests, setSelectedRequests] =
    React.useState<DeliveryRequest[]>();
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const handleClickOpen = (requests: DeliveryRequest[]) => {
    setSelectedRequests(requests);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedRequests([]);
    setOpen(false);
  };

  const groupedRequest = mapValues(
    groupBy(requests, (request) => dayjs(request.createdAt).format("MM/YYYY")),
    (requests) =>
      groupBy(requests, (request) => dayjs(request.createdAt).format("D"))
  );

  const getDays = () => {
    const day = selectedMonth.startOf("month").day();
    const totalDays = [
      ...Array(day === 0 ? 6 : day - 1).fill(""),
      ...Array.from({ length: selectedMonth.daysInMonth() }, (_, i) => i + 1),
    ];
    return totalDays;
  };

  const getColor = (request: DeliveryRequest) => {
    const deliveries = deliveriesByRequestId[request.id] || [];
    const providersForDelivery = uniq(
      deliveries.map((delivery) => delivery.provider)
    );
    const flags = providers.map((provider) =>
      providersForDelivery.includes(provider)
    );
    if (flags.includes(true) && flags.includes(false)) {
      return "warning.main";
    } else if (flags.includes(true)) {
      return "success.main";
    } else {
      return "error.main";
    }
  };

  const onLeft = () => {
    setSelectedMonth(selectedMonth.subtract(1, "month"));
  };
  const onRight = () => {
    setSelectedMonth(selectedMonth.add(1, "month"));
  };

  const upHandler = (event: KeyboardEvent) => {
    if (event.key === "ArrowLeft") {
      onLeft();
    } else if (event.key === "ArrowRight") {
      if (dayjs().diff(selectedMonth, "month") === 0) {
        return;
      }
      onRight();
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keyup", upHandler);
    };
  }, [selectedMonth]);
  return (
    <>
      <Dialog
        open={open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {selectedRequests &&
            dayjs(get(selectedRequests[0], "createdAt")).format("DD MMMM YYYY")}
        </DialogTitle>
        <DialogContent>
          {selectedRequests && (
            <TableView
              manualRequests={selectedRequests}
              deliveriesByRequestId={deliveriesByRequestId}
              providers={providers}
            />
          )}
        </DialogContent>
      </Dialog>
      <Box sx={{ m: 2 }}>
        <Box my={2} display={"flex"} justifyContent={"space-between"}>
          <Button onClick={onLeft} size="small">
            <ArrowLeft />
            Back
          </Button>
          <Typography>{selectedMonth.format("MMMM YYYY")}</Typography>
          <Button
            onClick={onRight}
            disabled={dayjs().diff(selectedMonth, "month") === 0}
            size="small"
          >
            Next
            <ArrowRight />
          </Button>
        </Box>
        <Paper>
          <Grid container>
            {weekDays.map((day) => (
              <Grid key={day} item xs={12 / 7}>
                <ItemHeader>{day}</ItemHeader>
              </Grid>
            ))}
            {getDays().map((value, index) => (
              <Grid key={index} item xs={12 / 7}>
                <Item
                  onClick={() => {
                    const requests =
                      groupedRequest[selectedMonth.format("MM/YYYY")]?.[value];
                    if (requests?.length) {
                      handleClickOpen(requests);
                    }
                  }}
                  sx={{
                    backgroundColor:
                      dayjs().diff(selectedMonth, "month") === 0 &&
                      dayjs().day() + 1 === value
                        ? "#063863"
                        : null,
                  }}
                >
                  <Typography>{value}</Typography>
                  <Box display={"flex"} justifyContent={"flex-end"}>
                    {groupedRequest[selectedMonth.format("MM/YYYY")]?.[
                      value
                    ]?.map((request) => {
                      return (
                        <Box
                          key={request.id}
                          sx={{
                            width: 10,
                            height: 10,
                            ml: 1,
                            borderRadius: "50%",
                            backgroundColor: getColor(request),
                          }}
                        ></Box>
                      );
                    })}
                  </Box>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default CalendarView;
