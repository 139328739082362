import { Alert, Button } from "@mui/material";
import UserContext from "Contexts/UserContext";
import React, { useContext } from "react";

const BuildInProgressCheck: React.FC = () => {
  const { env } = useContext(UserContext);

  const openActiveBuildCheck = () => {
    const jenkinsBuildUrl =
      env === "DEV"
        ? "https://github.com/leanspace/leanspace-infra/actions/workflows/deploy-to-dev.yml"
        : "https://github.com/leanspace/leanspace-infra/actions/workflows/deploy-to-demo-prod.yml";
    window.open(jenkinsBuildUrl, "_blank");
  };

  return (
    <Alert
      severity="warning"
      action={
        <Button color="inherit" size="small" onClick={openActiveBuildCheck}>
          Check
        </Button>
      }
    >
      Please make sure no deployment is running before proceeding.
    </Alert>
  );
};

export default BuildInProgressCheck;
